<template>
<!--  <AboutMe />-->
  <About />
</template>
<script>
import AboutMe from "@/components/AboutMe";
// import About from "@/components/About2";
import About2 from "@/components/About2";
export default {
  name: "about",
  components: {
    // AboutMe
    About2
  }
}
</script>
